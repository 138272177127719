export class BaseApi {

    /**
     * @constructor
     */
    constructor() {
        this.currentDomainZone = document.location.hostname.split('.')[document.location.hostname.split('.').length - 1];

        this.baseUrl = process.env.NODE_ENV === 'production'
            ? 'https://webvork.' + this.currentDomainZone + '/api/v1/'
            : 'http://webvork.' + this.currentDomainZone + '/api/v1/';


        this.NEED_USE_TEST_DOMAIN = parseInt(process.env.VUE_APP_NEED_USE_TEST_DOMAIN) === 1;

        if (this.NEED_USE_TEST_DOMAIN) {
            this.baseUrl = 'https://webvork.test/api/v1/'
        }

        this.config = {};
    }

    /**
     * Prepare request headers
     */
    prepareRequest() {
        this.config.headers = {
            Authorization: `Bearer ${WV.store.getters['auth/getToken']}`
        };
    }

    /**
     * GET request
     * @method get
     * @param {string} endpoint - URL
     */
    get(endpoint) {
        this.prepareRequest();
        return new Promise((resolve, reject) => {
            axios.get(this.baseUrl + endpoint, this.config)
                .then(response => {
                    resolve(response.data);
                })
                .catch(response => {
                    reject(response);
                    this.requestRejected(response);
                });
        });
    }

    /**
     * POST request
     * @method post
     * @param {string} endpoint - URL
     * @param {object} payload - Request payload
     */
    post(endpoint, payload) {
        this.prepareRequest();
        return new Promise((resolve, reject) => {
            axios.post(this.baseUrl + endpoint, payload, this.config)
                .then(response => {
                    resolve(response.data);
                })
                .catch(response => {
                    reject(response);
                    this.requestRejected(response);
                });
        });
    }

    /**
     * PUT request
     * @method put
     * @param {string} endpoint - URL
     * @param {object} payload - Request payload
     */
    put(endpoint, payload) {
        this.prepareRequest();
        return new Promise((resolve, reject) => {
            axios.put(this.baseUrl + endpoint, payload, this.config)
                .then(response => {
                    resolve(response.data);
                })
                .catch(response => {
                    reject(response);
                    this.requestRejected(response);
                });
        });
    }

    /**
     * Delete request
     * @method delete
     * @param {string} endpoint - URL
     * @param {object} payload - Request payload
     */
    delete(endpoint, payload) {
        this.prepareRequest();
        this.config.data = payload;
        return new Promise((resolve, reject) => {
            axios.delete(this.baseUrl + endpoint, this.config)
                .then(response => {
                    resolve(response.data);
                })
                .catch(response => {
                    reject(response);
                    this.requestRejected(response);
                });
        });
    }


    requestRejected(error) {
        if (typeof error.response.status !== 'undefined') {

            if (error.response.status === 401) {
                WV.store.dispatch('auth/logout');
            }
            if (error.response.status === 500) {
                if (WV.env.isLocal || WV.env.isStage) {
                    WV.toast.error(error.response.data.message, 10000);
                } else {
                    WV.toast.error('Something went wrong', 10000);
                }
            }

        }
    }

}

export default class Api extends BaseApi {
    constructor() {
        super();
    }

    postLogin(name, password) {
        return this.post('login', {name: name, password: password});
    }

    postImpersonate(token) {
        return this.post('impersonate', {token: token});
    }

    postRegister(payload) {
        return this.post('registration', payload);
    }

    postRecoveryPasswordSendEmail(email) {
        return this.post('forgot/step1', {email: email});
    }

    postRecoveryPasswordSetNewPassword(token, password, email) {
        return this.post('forgot/step2', {
            token: token,
            password: password,
            email: email
        });
    }

    getUser() {
        return this.get('user');
    }

    updateUser(payload) {
        return this.post('user', payload);
    }

    getPaymentSystems() {
        return this.get('dict/payment_system');
    }

    getPaymentSystem(id) {
        return this.get(`payment_system/${id}`);
    }

    postPaymentSystem(id, payload) {
        return this.post(`payment_system/${id}`, payload);
    }

    getOffers() {
        return this.get('offers?limit=100');
    }

    getOffer(offer_id) {
        return this.get(`offers/${offer_id}`);
    }

    getTopOffers() {
        return this.get('offers?is_top=1');
    }

    getStatMini() {
        return this.get('stat/mini');
    }

    getStatToday() {
        return this.get('stat/today');
    }

    getStatSession(filters) {
        return this.get(`stat/session?${filters}`);
    }

    getStatReferral(filters) {
        return this.get(`stat/referral?${filters}`);
    }

    getStatLeads(filters) {
        return this.get(`stat/lead?${filters}`);
    }

    getStatAudience(filters) {
        return this.get(`stat/audience?${filters}`);
    }

    getManagerInfo() {
        return this.get('manager-info');
    }

    getTickets() {
        return this.get('ticket?limit=1000');
    }

    getTicket(id) {
        return this.get(`ticket/${id}`);
    }

    putTicket(ticket) {
        return this.put(`ticket/${ticket.id}`, ticket);
    }

    createTicket({subject, message}) {
        return this.post(`ticket`, {subject, message});
    }

    createTicketMessage({ticketId, message}) {
        return this.post(`ticket/${ticketId}/message`, {message: message});
    }

    getTrafficSources() {
        return this.get('sources');
    }

    getGraphLeadStat(from, to) {
        return this.get(`graph-lead-stat?from=${from}&to=${to}`);
    }

    getPayments(from, to, limit = 1000) {
        return this.get(`payments?from=${from}&to=${to}&limit=${limit}`);
    }

    getLanding(landing_id) {
        return this.get(`landing/${landing_id}`);
    }

    getLandings(offer_id, geo) {
        return this.get(`landing?limit=200&offer_id=${offer_id}&geo=${geo}`);
    }

    getPrelanding(prelanding_id) {
        return this.get(`prelanding/${prelanding_id}`);
    }

    getPreLandings(offer_id, geo) {
        return this.get(`prelanding?limit=200&offer_id=${offer_id}&geo=${geo}`);
    }

    getStreams() {
        return this.get('stream?limit=1000');
    }

    getStream(stream_id) {
        return this.get(`stream/${stream_id}`);
    }

    createStream(payload) {
        return this.post('stream', payload);
    }

    updateStream(payload) {
        return this.put('stream', payload);
    }

    deleteStream(stream_id) {
        return this.delete('stream', {stream_id: stream_id});
    }

    getNews() {
        return this.get('news');
    }

    getPoll() {
        return this.get('poll');
    }

    savePollAnswers(payload) {
        return this.post('poll', payload);
    }

    getDomains() {
        return this.get('domain');
    }

    createDomain(domain) {
        return this.post('domain', {domain: domain});
    }

    deleteDomain(domain_url) {
        return this.delete('domain', {domain: domain_url});
    }

    getPostbacks() {
        return this.get(`postback`);
    }

    getPostbackLogs(postback_id) {
        return this.get(`postback-logs?limit=200&postback_id=${postback_id}`);
    }

    getShopProducts() {
        return this.get(`webvorkshop/products`);
    }

    getShopOrders() {
        return this.get(`webvorkshop/orders`);
    }

    getShopProduct(product_id) {
        return this.get(`webvorkshop/products/${product_id}`);
    }

    getShopTaxons() {
        return this.get(`webvorkshop/taxons`);
    }

    postShopCheckout(order) {
        return this.post('webvorkshop/checkout', order);
    }



}
