<template>
    <header id="webmaster-header-header" class="webmaster-header__header">
        <div
            @click="openSidebar"
            class="webmaster-header__burger">
            <SvgUse :width="24" :height="24" id="burger" key="icon-burger"></SvgUse>
        </div>
        <div class="wv-block--header">
            <h3>{{ $lng.__('lk_webmaster_main_menu', $route.meta.title) }}</h3>
        </div>

<!--        <div
            v-if="statMini"
            class="webmaster-header__balance">
            <div class="webmaster-header__balance_content">
                <div class="webmaster-header__balance_label">
                    {{ $lng.__('lk_webmaster_header', "Arabic Holidays") }}
                </div>
                <p class="webmaster-header__balance_sum">{{ statMini.action_bonus }}</p>
            </div>
            <div class="webmaster-header__balance_logo">
                <img src="https://minio.cdnwv.com/lk.webvork/images/icon_header_promo_arabian_vacations_v2_80x80.png" alt="Arabian vacations"/>
            </div>
        </div>-->

        <div
            v-if="statMini"
            class="webmaster-header__balance">

            <div
                v-show="$store.getters['webmaster/lastDashboardUpdate']"
                class="webmaster-header__balance__tooltip"
                :data-tippy-content="$lng.__('lk_webmaster_dashboard', 'Updated')+' '+$store.getters['webmaster/lastDashboardUpdate']"
            ></div>

            <div class="webmaster-header__balance_content">
                <div class="webmaster-header__balance_label">
                    {{ $lng.__('lk_webmaster_header', 'Bonuses') }}
                </div>
                <p class="webmaster-header__balance_sum">{{ statMini.bonus == null ? '0' : statMini.bonus }}</p>
            </div>
            <div class="webmaster-header__balance_logo">
                <svg width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="20" cy="20.5" r="20" fill="white"/>
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M30.0101 12.4772H27.6701C27.5401 12.4772 27.2801 12.623 27.2801 12.7689L24.5501 23.5633L23.7701 21.5211L26.1101 12.4772H25.2001C25.0701 12.4772 24.8101 12.623 24.8101 12.7689L23.1201 19.7707L21.8201 16.2698C21.6901 16.1239 21.5601 15.978 21.3001 15.978H20.0001H18.7001C18.5701 15.978 18.4401 16.1239 18.3101 16.2698L16.8801 19.7707L15.1901 12.7689C15.0601 12.623 14.9301 12.4772 14.8001 12.4772H13.8901L16.2301 21.5211L15.4501 23.5633L12.7201 12.7689C12.5901 12.623 12.4601 12.4772 12.3301 12.4772H9.9901C9.7301 12.4772 9.6001 12.7689 9.6001 13.0606L13.3701 28.2311C13.5001 28.377 13.6301 28.5229 13.7601 28.5229H16.3601C16.4901 28.5229 16.6201 28.377 16.7501 28.2311L17.4001 26.6266L17.7901 28.2311C17.7901 28.377 18.0501 28.5229 18.1801 28.5229H18.9601L18.0501 24.8761L20.0001 20.0624L21.8201 24.7302L20.9101 28.5229H21.6901C21.8201 28.5229 22.0801 28.377 22.0801 28.2311L22.4701 26.6266L23.1201 28.2311C23.1201 28.377 23.3801 28.5229 23.5101 28.5229H26.1101C26.2401 28.5229 26.5001 28.377 26.5001 28.2311L30.4001 13.0606C30.4001 12.7689 30.2701 12.4772 30.0101 12.4772Z"
                        fill="#5493A4"/>
                </svg>
            </div>
        </div>

        <div
            v-if="statMini"
            v-for="balance in statMini.balance"
            class="webmaster-header__balance">
            <div
                v-show="$store.getters['webmaster/lastDashboardUpdate']"
                class="webmaster-header__balance__tooltip"
                :data-tippy-content="$lng.__('lk_webmaster_dashboard', 'Updated')+' '+$store.getters['webmaster/lastDashboardUpdate']"
            ></div>

            <div class="webmaster-header__balance_content">
                <div class="webmaster-header__balance_label">
                    {{ $lng.__('lk_webmaster_header', 'Balance') }}
                </div>
                <p class="webmaster-header__balance_sum">{{ Number(balance.amount).toFixed(2) }}</p>
            </div>
            <div class="webmaster-header__balance_logo">
                <SvgUse
                    :width="40"
                    :height="40"
                    :id="balance.currency"
                    :key="'icon-'+balance.currency"
                ></SvgUse>
            </div>
        </div>

        <ShopCart></ShopCart>

        <WebmasterHeaderUserMenu></WebmasterHeaderUserMenu>

        <div
            v-if="statMini && user"
            class="webmaster-header__avatar">
            <AvatarLetter
                :size="50"
                :words="preparedAvatarWords"
            ></AvatarLetter>
            <span
                v-if="$store.getters['auth/countTicketsUnread']"
                @click="$router.push('/'+this.$route.params.lng+'/webmaster/support')"
                id="webmaster-header__avatar_notification"
                class="webmaster-header__avatar_notification ticket-notificaitions"
                tabindex="0">
                {{ $store.getters['auth/countTicketsUnread'] }}
            </span>
        </div>
    </header>
</template>

<script>
    import WebmasterHeaderUserMenu from './WebmasterHeaderUserMenu.vue'
    import ShopCart from './Webvorkshop/ShopCart.vue';

    export default {
        name: 'WebmasterHeader',
        props: [],
        data() {
            return {};
        },
        computed: {
            statMini() {
                return this.$store.state.webmaster.statMini || false
            },
            user() {
                return this.$store.state.auth.user
            },
            preparedAvatarWords() {
                let words = [];
                let firstName = typeof this.user.first_name !== 'object' && this.user.first_name !== ''
                    ? this.user.first_name.replaceAll(' ', '')
                    : ''
                let lastName = typeof this.user.last_name !== 'object' && this.user.last_name !== ''
                    ? this.user.last_name.replaceAll(' ', '')
                    : ''
                firstName+lastName !== ''
                    ? words.push(firstName+' '+lastName)
                    : words.push(this.user.username.replaceAll(' ', ''))
                return words
            },
        },
        methods: {
            openSidebar() {
                WV.Bus.emit('openSidebar')
            }
        },
        beforeMount() {
            this.$store.dispatch('webmaster/getStatMini')
            this.$store.dispatch('auth/getUser')
        },
        components: {
            WebmasterHeaderUserMenu,
            ShopCart,
        }
    };
</script>

<style lang="scss">

    @import '../../scss/utils/mixins.scss';
    @import '../../scss/utils/variables.scss';

    .webmaster-header {
        &__burger{
            width: 24px;
            height: 24px;
            margin-right: 15px;
            cursor: pointer;
            @include break(md){
                display: none;
            }
        }
        &__header {
            width: 100%;
            height: 100px;
            max-height: 100px;
            display: flex;
            align-items: center;
            position: relative;

            @include maxWidth(720) {
                height: 70px;
                position: fixed;
                padding: 0 20px;
                background-color: $color-day-white;
                transform: translateY(0);
                transition: transform 0.2s;
                z-index: $z-index-menu - 1;
            }

            .wv-block--header {
                margin-right: auto;
            }
        }

        &__balance{
            justify-content: space-between;
            align-items: center;
            margin-right: 15px;
            display: none;
            position: relative;
            z-index: 1;
            @include break(md) {
                display: flex;
            }
            @include break(lg) {
                margin-right: 35px;
            }
            &_content{
                display: flex;
                flex-direction: column;
            }
            &_label{
                font-size: 12px;
                line-height: 16px;
                letter-spacing: 0.5px;
                text-align: right;
                font-family: $font-secondary;
                color: $color-day-dark-blue;
                opacity: .5;
            }
            &_sum{
                font-weight: bold;
                font-size: 18px;
                line-height: 22px;
                text-align: right;
                letter-spacing: 0.005em;
            }
            &_logo{
                width: 40px;
                height: 40px;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-left: 10px;
            }
            &__tooltip{
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                width: 100%;
                height: 100%;
                z-index: 3;
            }
        }

        &__profile {
            &-block {
                display: flex;
                flex-direction: row;
                font-family: $font-secondary;
                font-style: normal;
                letter-spacing: 1px;
                margin-right: 5px;

                @include maxWidth(720) {
                    display: none;
                }
            }

            &_info {
                display: flex;
                flex-direction: column;
                align-items: flex-end;

                @include maxWidth(1100) {
                    display: none;
                }
            }

            &_name {
                font-weight: bold;
                font-size: 17px;
                line-height: 23px;
                color: $color-day-lmenu-normal;
            }
            &_id {
                font-weight: normal;
                font-size: 14px;
                line-height: 16px;
                color: $color-day-form-shadow;
            }
            &_arrow {
                height: 50px;
                min-height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 0 10px;
                cursor: pointer;
                img {
                    transition: transform 0.2s;
                }
                &.wv-popup--active {
                    img {
                        transform: rotateZ(180deg);
                    }
                }
            }
        }

        &__avatar {
            width: 50px;
            height: 50px;
            flex: none;
            position: relative;
            z-index: $z-index-avatar;

            @include maxWidth(720) {
                display: none;
            }

            img {
                width: 100%;
                height: 100%;
                border-radius: 50%;
            }

            &_notification {
                width: $size-icon-20;
                height: $size-icon-20;
                border: 1px solid $color-day-white;
                position: absolute;
                bottom: 0;
                right: 0;
                z-index: $z-index-avatar + 1;

                font-weight: bold;
                font-size: 12px;
                line-height: 14px;
                letter-spacing: 0.5px;
                color: $color-day-white;
            }
        }
    }

</style>
