import menuWebmaster from './menuWebmaster.js';
import StreamModel from '../models/Stream';

export default {
    namespaced: true,
    state: () => ({
        menu: menuWebmaster,
        offer: false,
        offers: [],
        offers_view_mode: 'table',
        manager: false,
        statMini: false,
        statToday: false,
        tickets: [],
        graphLeadStat: {},
        stream: false,
        streams: [],
        landings: [],
        landing: false,
        prelandings: [],
        prelanding: false,
        paymentSystems: [],
        paymentSystem: false,
        news: [],
        payments: [],
        sessions: [],
        sessionsFilters: [],
        referrals: [],
        leads: [],
        audience: [],
        domains: [],
        postbacks: [],
        postbackLogs: [],
    }),
    mutations: {
        set(state, payload) {
            state[payload[0]] = payload[1];
        },
        setNews(state, news) {
            state.news = news;
        },
        setStatMini(state, statMini) {
            state.statMini = statMini;
        },
        setStatToday(state, statToday) {
            state.statToday = statToday;
        },
        setManager(state, manager) {
            state.manager = manager;
        },
        setTickets(state, tickets) {
            state.tickets = tickets;
        },
        setGraphLeadStat(state, stat) {
            state.graphLeadStat = stat;
        },
        setStream(state, stream) {
            state.stream = stream;
        },
        setOffersViewMode(state, mode) {
            if (mode === 'table' || mode === 'grid') state.offers_view_mode = mode;
        },
        clean(state, items) {
            Object.keys(items)
                .forEach(key => {
                    state[key] = items[key];
                });
        },
    },
    actions: {
        getOffers(store) {
            WV.Api.getOffers()
                .then(response => {
                    store.commit('set', ['offers', response.items]);
                });
        },
        getNews(store) {
            return new Promise((resolve, reject) => {
                WV.Api.getNews()
                    .then(response => {
                        store.commit('setNews', response);
                        resolve(response);
                    });
            });
        },
        getOffer(store, offer_id) {
            return new Promise((resolve, reject) => {
                WV.Api.getOffer(offer_id)
                    .then(response => {
                        store.commit('set', ['offer', response]);
                        resolve(response);
                    });
            });
        },
        getManagerInfo(store) {
            WV.Api.getManagerInfo()
                .then(response => {
                    store.commit('setManager', response);
                });
        },
        getStatMini(store) {
            WV.Api.getStatMini()
                .then(response => {
                    store.commit('setStatMini', response);
                    setTimeout(() => {
                        store.dispatch('getStatMini');
                    }, 60000);
                });
        },
        getStatToday(store) {
            WV.Api.getStatToday()
                .then(response => {
                    store.commit('setStatToday', response);
                    setTimeout(() => {
                        store.dispatch('getStatToday');
                    }, 5000);
                });
        },
        getStatLeads(store, filters) {
            return new Promise((resolve, reject) => {
                WV.Api.getStatLeads(filters)
                    .then(response => {
                        store.commit('set', ['leads', response.items]);
                        resolve(response);
                    });
            });
        },
        getStatAudience(store, filters) {
            return new Promise((resolve, reject) => {
                WV.Api.getStatAudience(filters)
                    .then(response => {
                        store.commit('set', ['audience', response.items]);
                        resolve(response);
                    });
            });
        },
        getStatReferral(store, filters) {
            return new Promise((resolve, reject) => {
                WV.Api.getStatReferral(filters)
                    .then(response => {
                        store.commit('set', ['referrals', response.items]);
                        resolve(response);
                    });
            });
        },
        getStatSession(store, filters) {
            return new Promise((resolve, reject) => {
                WV.Api.getStatSession(filters)
                    .then(response => {
                        store.commit('set', ['sessions', response.items]);
                        store.commit('set', ['sessionsFilters', response.filters]);
                        resolve(response);
                    });
            });
        },
        getTickets(store) {
            return new Promise((resolve, reject) => {
                WV.Api.getTickets()
                    .then(response => {
                        store.commit('setTickets', response.items);
                        resolve(response.items);
                    });
            });
        },
        getTicket(store, id) {
            return new Promise((resolve, reject) => {
                WV.Api.getTicket(id)
                    .then(response => {
                        resolve(response);
                    });
            });
        },
        putTicket(store, ticket) {
            return new Promise((resolve, reject) => {
                WV.Api.putTicket(ticket)
                    .then(response => {
                        resolve(response);
                    });
            });
        },
        createTicket(store, ticket) {
            return new Promise((resolve, reject) => {
                WV.Api.createTicket(ticket)
                    .then(response => {
                        resolve(response);
                    });
            });
        },
        createTicketMessage(store, {ticketId, message}) {
            return new Promise((resolve, reject) => {
                WV.Api.createTicketMessage({
                        ticketId: ticketId,
                        message: message
                    })
                    .then(response => {
                        resolve(response);
                    });
            });
        },

        getPayments(store, {from, to}) {
            return new Promise((resolve, reject) => {
                WV.Api.getPayments(from, to)
                    .then(response => {
                        store.commit('set', ['payments', response.items]);
                        resolve(response.items);
                    });
            });
        },
        getLandings(store, {offer_id, geo}) {
            return new Promise((resolve, reject) => {
                WV.Api.getLandings(offer_id, geo)
                    .then(response => {
                        store.commit('set', ['landings', response.items]);
                        resolve(response.items);
                    });
            });
        },
        getLanding(store, landing_id) {
            return new Promise((resolve, reject) => {
                WV.Api.getLanding(landing_id)
                    .then(response => {
                        store.commit('set', ['landing', response]);
                        resolve(response);
                    });
            });
        },
        getPrelandings(store, {offer_id, geo}) {
            return new Promise((resolve, reject) => {
                WV.Api.getPreLandings(offer_id, geo)
                    .then(response => {
                        store.commit('set', ['prelandings', response.items]);
                        resolve(response.items);
                    });
            });
        },
        getPrelanding(store, prelanding_id) {
            return new Promise((resolve, reject) => {
                if (prelanding_id === null) {
                    reject();
                } else {
                    WV.Api.getPrelanding(prelanding_id)
                        .then(response => {
                            store.commit('set', ['prelanding', response]);
                            resolve(response);
                        });
                }
            });
        },
        getStreams(store) {
            return new Promise((resolve, reject) => {
                WV.Api.getStreams()
                    .then(response => {
                        store.commit('set', ['streams', response.items]);
                        resolve(response.items);
                    });
            });
        },
        getGraphLeadStat(store, {from, to}) {
            return new Promise((resolve, reject) => {
                WV.Api.getGraphLeadStat(from, to)
                    .then(response => {
                        store.commit('setGraphLeadStat', response);
                        resolve();
                    });
            });
        },
        getStream(store, {stream_id, offer_id}) {
            store.commit('setStream', false);
            return new Promise((resolve, reject) => {
                if (stream_id > 0) {
                    WV.Api.getStream(stream_id)
                        .then(stream => {
                            store.commit('setStream', stream);
                            resolve(store.state.stream);
                        });
                } else {
                    store.commit('setStream', new StreamModel(offer_id).getModel());
                    resolve(store.state.stream);
                }
            });
        },
        saveStream(store, stream) {
            return new Promise((resolve => {
                WV.Api.updateStream(stream)
                    .then(response => {
                        if (response.stream_id) resolve();
                    });
            }));
        },
        createStream(store, stream) {
            return new Promise(resolve => {
                WV.Api.createStream(stream)
                    .then(resolve);
            });
        },
        deleteStream(store, stream) {
            return new Promise(resolve => {
                WV.Api.deleteStream(stream.stream_id)
                    .then(resolve);
            });
        },
        getPaymentSystems(store) {
            return new Promise((resolve, reject) => {
                WV.Api.getPaymentSystems()
                    .then(response => {
                        store.commit('set', ['paymentSystems', response]);
                        resolve(response);
                    });
            });
        },
        getPaymentSystem(store, id) {
            return new Promise((resolve, reject) => {
                WV.Api.getPaymentSystem(id)
                    .then(response => {
                        store.commit('set', ['paymentSystem', response]);
                        resolve(response);
                    });
            });
        },
        addPaymentSystem(store, {id, payload}) {
            return new Promise((resolve, reject) => {
                WV.Api.postPaymentSystem(id, payload)
                    .then(response => {
                        resolve(response);
                    });
            });
        },
        getDomains(store) {
            return new Promise((resolve, reject) => {
                WV.Api.getDomains()
                    .then(response => {
                        store.commit('set', ['domains', response.items]);
                        resolve(response);
                    });
            });
        },
        createDomain(store, domain) {
            return new Promise((resolve, reject) => {
                WV.Api.createDomain(domain)
                    .then(response => {
                        store.dispatch('getDomains');
                        resolve(response);
                    })
                    .catch(response => {
                        reject(response);
                    });
            });
        },
        deleteDomain(store, domain) {
            return new Promise(resolve => {
                WV.Api.deleteDomain(domain.url).then(resolve);
            });
        },
        getPostbacks(store) {
            return new Promise((resolve, reject) => {
                WV.Api.getPostbacks()
                    .then(response => {
                        store.commit('set', ['postbacks', response.items]);
                        resolve(response);
                    });
            });
        },
        getPostbackLogs(store, postback_id) {
            return new Promise((resolve, reject) => {
                WV.Api.getPostbackLogs(postback_id)
                    .then(response => {
                        store.commit('set', ['postbackLogs', response.items]);
                        resolve(response);
                    });
            });
        },

    },
    getters: {
        offersOnlyTop(state) {
            return state.offers.filterBy({is_top: true});
        },
        dashboardNews(state) {
            return state.news.reverse()
                .slice(0, 5);
        },
        lastDashboardUpdate(state) {
            let lastUpdate = state.statToday.last_update;
            if (!lastUpdate) return false;

            let d = new Date(lastUpdate);

            let hours = '' + d.getHours();
            let minutes = '' + d.getMinutes();
            if (hours.length < 2) hours = '0' + hours;
            if (minutes.length < 2) minutes = '0' + minutes;

            let formattedDate = [hours, minutes].join(':');

            let month = '' + (d.getMonth() + 1);
            let day = '' + d.getDate();
            let year = d.getFullYear();
            if (month.length < 2) month = '0' + month;
            if (day.length < 2) day = '0' + day;
            formattedDate += ', ' + [day, month, year].join('.');

            return formattedDate;
        },
    }
};
